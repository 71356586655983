<template>
  <div class="my-header">
    <header class="page-header">
      <div class="rd-navbar-wrap">
        <nav class="rd-navbar rd-navbar-top-panel-light novi-background" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-sm-device-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-device-layout="rd-navbar-fullwidth" data-lg-layout="rd-navbar-fullwidth" data-stick-up-clone="true" data-md-stick-up-offset="90px" data-lg-stick-up-offset="90px" data-stick-up="true" data-sm-stick-up="true" >
          <div class="rd-navbar-inner">
            <div class="rd-navbar-nav-wrap">
              <ul class="rd-navbar-nav">
                <li><router-link :to="{name: 'home'}">Home</router-link></li>
                <li><router-link :to="{name: 'about'}">About Us</router-link></li>
                <li><router-link :to="{name: 'services'}"> Services</router-link></li>
                <li><router-link :to="{name: 'portfolio'}"> Portfolio</router-link></li>
              </ul>
            </div>
            <div class="rd-navbar-panel">
              <button class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
              <div class="rd-navbar-brand"><router-link :to="{name: 'home'}" class="brand-name" ><img :src="logo.value" alt="" width="100" height="28"></router-link></div>
            </div>
            <div class="rd-navbar-top-panel">
              <div class="rd-navbar-top-panel-toggle" data-rd-navbar-toggle=".rd-navbar-top-panel"><span></span></div>
              <div class="rd-navbar-top-panel-content">
                <ul class="rd-navbar-nav">
                  <li><router-link :to="{name: 'ourTeam'}"> Our Team</router-link></li>
                  <li><router-link :to="{name: 'blog'}"> Blog </router-link></li>
                  <li><router-link :to="{name: 'contact'}"> Contact </router-link></li>
                  <li class="rd-navbar-nav-wrap-content">
                    <div class="group-md"><router-link :to="{name: 'login'}" class="btn btn-sm btn-primary btn-effect-ujarak" href="#">Login</router-link></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "MyHeader",
  data(){
    return {
      logo: []
    }
  },
  created() {
    this.$http.get('navbar-logo').then(response => {
      this.logo = response.body;
    });
  },
  mounted() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
        "src",
        "/js/script.js"
    );
    document.head.appendChild(recaptchaScript);
  },


}
</script>

<style scoped>

</style>