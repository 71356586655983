<template>
  <div class="my-footer">
    <!-- Footer Widgets Start -->
    <footer class="page-footer">
      <section class="section pre-footer-minimal bg-style-2 novi-background bg-image">
        <div class="pre-footer-minimal-inner">
          <div class="container text-center text-sm-left">
            <div class="row justify-content-sm-center spacing-55">
              <div class="col-sm-12 col-lg-3">
                <div class="footer-links style-3">
                  <div class="brand-sm mar-bottom-4"><router-link :to="{name:'home'}" href="index-11.html"><img :src="logo.value" alt=""></router-link></div>
                  <p v-html="footerText.value"></p>
                </div>
              </div>
              <div class="col-sm-4 col-lg-2">
                <div class="footer-links style-3">
                  <h4>Navigation</h4>
                  <ul>
                    <li><router-link :to="{name: 'home'}">Home</router-link></li>
                    <li><router-link :to="{name: 'about'}">About Us</router-link></li>
                    <li><router-link :to="{name: 'services'}"> Services</router-link></li>
                    <li><router-link :to="{name: 'portfolio'}"> Portfolio</router-link></li>
                    <li><router-link :to="{name: 'blog'}"> Blog</router-link></li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-12 col-lg-4">
                <div class="footer-links style-3">
                  <h4>Recent Posts</h4>
                  <ul class="recent-posts">
                    <li v-for="(blog, key3) in blogs" :key="key3">
                      <div class="mini-post-img"><a href="#"><img :src="blog.image" alt="" style="height: 60px"></a></div>
                      <div class="post-content"><a href="#">{{ blog.title }}</a>
                        <time class="text-secondary">{{ blog.date }}</time>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-12 col-lg-3">
                <div class="footer-links style-3">
                  <h4>Contact Us</h4>
                  <ul class="addresss-info gray-style mar-top-2">
                    <li><i class="fa fa-map-marker"></i>
                      <p>{{address.value}}</p>
                    </li>
                    <li v-for="(phone, key) in phones" :key="key"><i class="fa fa-phone"></i><a href="callto:#">{{ phone.value }}</a></li>
                    <li v-for="(email, key2) in emails" :key="key2"><i class="fa fa-envelope-o"></i><a href="mailto:#">{{ email.value }}</a></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <hr class="gray light">
        </div>
      </section>
      <section class="page-footer-default bg-style-2 copyright novi-background bg-image">
        <div class="container">
          <div class="row copyright style-2">
            <div class="col-lg-6 text-xl-left">
              <p class="rights" v-html="copyright.value"></p>
            </div>
            <div class="col-lg-6 text-xl-right">
              <ul class="inline-list-xxs">
                <li><a class="icon novi-icon icon-xxs icon-circle icon-gray-outline icon-effect-1 fa fa-linkedin" :href="linkedIn.value" target="_blank"></a></li>
                <li><a class="icon novi-icon icon-xxs icon-circle icon-gray-outline icon-effect-1 fa fa-facebook" :href="facebook.value" target="_blank" ></a></li>
                <li><a class="icon novi-icon icon-xxs icon-circle icon-gray-outline icon-effect-1 fa fa-twitter" :href="twitter.value" target="_blank"></a></li>
                <li><a class="icon novi-icon icon-xxs icon-circle icon-gray-outline icon-effect-1 fa fa-google-plus" :href="googlePlus.value" target="_blank"></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </footer>

    <!-- Footer Widgets End -->
  </div>
</template>

<script>
export default {
  name: "MyFooter",
  data(){
    return {
      logo: [],
      copyright: [],
      address: [],
      phones: [],
      emails: [],
      facebook: [],
      googlePlus: [],
      twitter: [],
      linkedIn: [],
      footerText: [],
      blogs: [],
    }
  },
  created() {
    this.$http.get('footer-logo').then(response => {
      this.logo = response.body;
    });

    this.$http.get('copyright-text').then(response => {
      this.copyright = response.body;
    });

    this.$http.get('address').then(response => {
      this.address = response.body;
    });

    this.$http.get('phone').then(response => {
      this.phones = response.body;
    });

    this.$http.get('email').then(response => {
      this.emails = response.body;
    });

    this.$http.get('facebook').then(response => {
      this.facebook = response.body;
    });

    this.$http.get('linkedIn').then(response => {
      this.linkedIn = response.body;
    });

    this.$http.get('googlePlus').then(response => {
      this.googlePlus = response.body;
    });

    this.$http.get('twitter').then(response => {
      this.twitter = response.body;
    });

    this.$http.get('footer-text').then(response => {
      this.footerText = response.body;
    });

    this.$http.get('footer-blogs').then(response => {
      this.blogs = response.body;
    });
  },
}
</script>

<style scoped>
.pre-footer-minimal-inner {
  padding-top: 20px;
  padding-bottom: 0;
}
.copyright.style-2 {
  padding: 0;
  color: rgba(0, 0, 0, 0.7);
}

.copyright{
  padding: 12px 0;
}

.footer-links {
  margin-top: 20px;
  margin-bottom: 5px;
  text-align: left;
}

</style>